<!-- src/views/About.vue -->
<template>
  <section class="about-container">
    <header class="about-hero">
      <h1>About DC Software Solutions</h1>
      <p>Innovative solutions by Dimitri Croes, an expert in data-driven software development, physics modeling, and AI.</p>
    </header>

    <section class="about-content">
      <h2>Meet the Founder</h2>
      <p>Hello! I'm Dimitri Croes, the founder and driving force behind DC Software Solutions, a one-person company dedicated to delivering customized, high-impact software solutions. My background in engineering physics, data science, and cyber security gives me the skills to tackle complex challenges, from building models grounded in physics principles to designing robust data-driven applications.</p>
      
      <h2>My Work</h2>
      <p>I currently work as a full-stack developer on EUROCONTROL MUAC's contrail avoidance project, which aims to reduce the climate impact of aircraft contrails. My contributions to the project range from developing machine learning models and coding for Raspberry Pi hardware nodes to integrating data sources and creating frontends and dashboards that visualize impactful insights. I also manage the server architecture using Docker to support this multi-component system.</p>

      <h2>My Expertise</h2>
      <ul>
        <li><strong>Data-Driven Applications:</strong> Transforming raw data into actionable insights through custom-built applications designed for specific client needs.</li>
        <li><strong>Physics-Based Modeling:</strong> Applying physics principles to create realistic and accurate models, particularly suited for complex, data-rich environments.</li>
        <li><strong>Data Science & AI:</strong> Leveraging advanced algorithms and AI to reveal patterns, automate processes, and deliver predictive insights that fuel smarter decision-making.</li>
      </ul>

      <h2>Mission & Vision</h2>
      <p>At DC Software Solutions, I aim to bridge the gap between technology and business needs. My mission is to deliver innovative, efficient solutions that empower clients to leverage their data for sustainable growth and operational improvement.</p>

      <h2>Why Choose DC Software Solutions?</h2>
      <p>As a single-person company, I provide a level of dedication and personalized attention that larger firms often can't match. When you partner with DC Software Solutions, you're gaining a committed partner with expertise in engineering, physics, and data science who values precision, innovation, and integrity.</p>

      <router-link to="/contact" class="btn-primary">Get in Touch</router-link>
    </section>
  </section>
</template>


<script>
export default {
  name: 'AboutMe',
};
</script>

<style scoped>
.about-container {
  text-align: center;
  padding: 3rem;
  font-family: Arial, sans-serif;
  color: #333;
}

.about-hero {
  background-color: #f0f8ff;
  padding: 3rem 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.about-hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #007acc;
}

.about-hero p {
  font-size: 1.2rem;
  color: #555;
}

.about-content {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  text-align: left;
}

.about-content h2 {
  font-size: 2rem;
  color: #005f8a;
  margin-bottom: 1rem;
}

.about-content p, .about-content ul {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.about-content ul {
  list-style-type: none;
  padding: 0;
}

.about-content li {
  margin-bottom: 1rem;
}

.btn-primary {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background-color: #007acc;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #005f8a;
}
</style>
