<!-- src/views/Contact.vue -->
<template>
    <section class="contact-container">
      <header class="contact-hero">
        <h1>Contact DC Software Solutions</h1>
        <p>Let's discuss how we can work together to bring your project to life!</p>
      </header>
  
      <section class="contact-details">
        <h2>Get in Touch</h2>
        <p>If you have any questions or want to discuss a project, feel free to reach out using the form below or through the contact details provided.</p>
  
        <div class="contact-info">
          <h3>Email:</h3>
          <p><a href="mailto:info@dcsoftwaresolutions.nl">info@dcsoftwaresolutions.nl</a></p>
        </div>
      </section>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ContactPage',
    data() {
      return {
        formData: {
          name: '',
          email: '',
          message: ''
        },
        successMessage: ''
      };
    },
    methods: {
      submitForm() {
        // Here, you would typically send the form data to your server.
        // For now, we will just display a success message.
        this.successMessage = 'Thank you for your message! I will get back to you soon.';
        this.formData.name = '';
        this.formData.email = '';
        this.formData.message = '';
      }
    }
  };
  </script>
  
  <style scoped>
  .contact-container {
    text-align: center;
    padding: 3rem;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .contact-hero {
    background-color: #f0f8ff;
    padding: 3rem 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contact-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #007acc;
  }
  
  .contact-details {
    margin-top: 3rem;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .contact-details h2 {
    font-size: 2rem;
    color: #005f8a;
    margin-bottom: 1rem;
  }
  
  .contact-info h3 {
    margin-top: 1rem;
  }
  
  .contact-form {
    margin-top: 3rem;
  }
  
  .contact-form h2 {
    font-size: 2rem;
    color: #005f8a;
    margin-bottom: 1rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 150px;
  }
  
  .btn-primary {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: white;
    background-color: #007acc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #005f8a;
  }
  
  .success-message {
    margin-top: 1rem;
    color: green;
    font-weight: bold;
  }
  </style>
  