<!-- src/App.vue -->
<template>
  <div id="app">
    <header>
      <nav aria-label="Main Navigation" class="nav-bar">
        <ul>
          <li><router-link to="/" exact-active-class="active-link">Home</router-link></li>
          <li><router-link to="/about" exact-active-class="active-link">About</router-link></li>
          <li><router-link to="/contact" exact-active-class="active-link">Contact</router-link></li>
        </ul>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<style scoped>
#app {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007acc; /* Changed for a brighter look */
  padding: 15px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px; /* Increased spacing between items */
}

.nav-bar a {
  color: white;
  text-decoration: none;
  padding: 10px 15px; /* Added padding for clickable area */
  border-radius: 5px; /* Rounded corners for links */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.nav-bar a:hover {
  background-color: #005f8a; /* Darker background on hover */
  color: #e0e0e0; /* Light color for better contrast on hover */
}

.nav-bar a.active-link {
  font-weight: bold;
  text-decoration: underline;
  background-color: #005f8a; /* Active link background for visibility */
  color: #e0e0e0; /* Light color for active link */
}

main {
  padding: 20px;
}
</style>
