<!-- src/views/Home.vue -->
<template>
  <section class="home-container">
    <header class="hero">
      <h1>Welcome to DC Software Solutions</h1>
      <p>Your partner in innovative software engineering and digital transformation.</p>
      <router-link to="/about" class="btn-primary">Learn More About Us</router-link>
    </header>
    
    <section class="services">
      <h2>Our Services</h2>
      <p>At DC Software Solutions, we specialize in data-driven applications, physics-based modeling, and advanced AI solutions to help drive your success.</p>
      <ul>
        <li>
          <h3>Custom Software Development</h3>
          <p>Tailored solutions designed to meet your specific business needs.</p>
        </li>
        <li>
          <h3>Cloud Integration</h3>
          <p>Secure, scalable cloud services for enhanced business agility.</p>
        </li>
        <li>
          <h3>Data Analytics & AI</h3>
          <p>Insights-driven decision-making with advanced analytics and AI solutions.</p>
        </li>
      </ul>
    </section>

    <section class="contact-cta">
      <h2>Get in Touch</h2>
      <p>Have a project in mind? Let's make it happen together.</p>
      <router-link to="/contact" class="btn-secondary">Contact Us</router-link>
    </section>
  </section>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.home-container {
  text-align: center;
  padding: 3rem;
  font-family: Arial, sans-serif;
  color: #333;
}

.hero {
  background: linear-gradient(135deg, #007acc, #005f8a);
  color: #fff;
  padding: 4rem 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.services {
  margin-top: 4rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.services h2 {
  font-size: 2.5rem;
  color: #007acc;
  margin-bottom: 1rem;
}

.services p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #555;
}

.services ul {
  list-style: none;
  padding: 0;
}

.services li {
  margin-bottom: 2rem;
}

.services h3 {
  font-size: 1.5rem;
  color: #005f8a;
  margin-bottom: 0.5rem;
}

.services p {
  color: #666;
}

.contact-cta {
  margin-top: 4rem;
  padding: 2rem;
  background-color: #e8f4fc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.contact-cta h2 {
  font-size: 2.5rem;
  color: #007acc;
  margin-bottom: 1rem;
}

.contact-cta p {
  font-size: 1.1rem;
  color: #555;
}

.btn-primary, .btn-secondary {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007acc;
}

.btn-primary:hover {
  background-color: #005f8a;
}

.btn-secondary {
  background-color: #005f8a;
}

.btn-secondary:hover {
  background-color: #004366;
}
</style>
